import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.banner--home .actus--slider').slick({
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: '<span class="slick--prev"><i class="fal fa-angle-left"></i><span>',
		nextArrow: '<span class="slick--next"><i class="fal fa-angle-right"></i><span>'
	});	


});