import $ from 'jquery';

$(document).ready(function() {
	
	if (window.matchMedia("(min-width: 922px)").matches) {
		var height = $( ".banner--page .banner--page--actus div" ).height();
		
		var result = height / 1.25;
	
		$( ".banner--page .banner--page--actus" ).css('bottom', `-${result}px`);
		$( ".content" ).css('padding-top', `${result}px`);

	} else {
		//
	}
	
});