import $ from 'jquery';

$(document).ready(function() {
	
	//HEADER

	$(window).scroll(function(){
	    if ($(window).scrollTop() >= 200) {
	        $('header').addClass('header--sticky');
	    }
	    else {
	        $('header').removeClass('header--sticky');
	    }
	});
	
	$('.hamburger > div').click(function(){
		$('#mySidenav').css('width', '250px');
	});
		
	$('#mySidenav .close').click(function(){
		$('#mySidenav').css('width', '0');
	});

	//SUBMENU

    $(".menu-item-has-children > a").click(function (e) {
        
        e.preventDefault();
        
        $(".menu > .menu-item-has-children .menu-item-has-children .sub-menu").addClass('nonActive');
        $(this).next('.sub-menu').removeClass('nonActive');
        $(".menu > .menu-item-has-children  .menu-item-has-children .sub-menu.nonActive").slideUp(200);

        $(this).next('.sub-menu').slideToggle(200);
    });

	//SEARCH
	
	$(".search").click(function(){
		$(".searchPopUp").fadeToggle(400);
	});
	
	$(".closePopUp").click(function(){
		$(".searchPopUp").fadeOut(400);
	});
	

});